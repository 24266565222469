import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import '../CreateAppModal.scss';
import PropTypes from 'prop-types';
import {
    getIsvUsers,
    getAppPartnersNames,
    getAppPartnersRepCodes,
    getProcessingBanks
} from './createAppReducer';

import { getConfigData } from './createAppActions';
import { createApp } from '../../../../../actions/createAppActions';

import { pricingTypes, optBlueOptions } from '../../../Application/StaticData/staticData';

import Modal from '../../../../Shared/Modal/Modal';
import Select from '../../../../Shared/Select/Select';
import { isChecked } from '../../../../../utils/form';
import _ from 'lodash';

export class CreateAppModal extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        handleCancel: PropTypes.func,
        partners: PropTypes.array,
        isvUsers: PropTypes.array,
        appPartnersRepCodes: PropTypes.array,
        isOpen: PropTypes.bool,
        handleSave: PropTypes.func
    };

    static defaultProps = {
        isvUsers: [],
        partners: [],
        processingBanks: []
    };

    state = {
        contact: '',
        partnerID: '',
        isAppCreating: false,
        isvRep: '',
        pricingType: '',
        optBlue: '',
        repCodes: '',
        checkCredit: true,
        hasPersonalGuarantor: true
    };

    componentDidMount() {
        this.props.dispatch(getConfigData());
    }

    handleSelectChange = (e) => this.setState({
        [e.target.name]: e.target.value
    });

    handleContactChange = (e) => this.setState({
        contact: e.target.value
    });

    handleCheckCreditChange = () => this.setState({
        checkCredit: !this.state.checkCredit
    });

    handleHasPersonalGuarantorChange = () => this.setState({
        hasPersonalGuarantor: !this.state.hasPersonalGuarantor
    });

    handleCancel = () => {
        this.setState({
            contact: '',
            partnerID: '',
            isAppCreating: false,
            isvRep: '',
            pricingType: '',
            optBlue: '',
            repCodes: '',
            checkCredit: true,
            hasPersonalGuarantor: true
        });
        this.props.handleCancel();
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { handleSave, appPartnersRepCodes } = this.props;

        const { partnerID, isvRep, pricingType, optBlue, contact, checkCredit, hasPersonalGuarantor } = this.state;

        const formElements = e.target.elements;
        const elements = [...formElements];
        const isFormValid = elements.every((element) => element.checkValidity());
        if (isFormValid) {
            // get repCode associated with partner and send it to createNewApp endpoint
            const repCodeObj = _.find(appPartnersRepCodes, (partner) => `${partner.value}` === partnerID);
            const partnerRepCode = _.get(repCodeObj, 'label');

            this.setState({
                isAppCreating: true
            });

            await this.props.dispatch(createApp({ partnerID, isvRep, pricingType, optBlue, contact, partnerRepCode, checkCredit, hasPersonalGuarantor }));
            handleSave();
            this.setState({
                contact: '',
                partnerID: '',
                isAppCreating: false,
                isvRep: '',
                pricingType: '',
                optBlue: '',
                repCodes: '',
                checkCredit: true,
                hasPersonalGuarantor: true
            });
        } else {
            toast.error('Please fill out all required fields');
            this.setState({
                isAppCreating: false
            });
        }

    };

    render() {
        const { contact, isAppCreating, checkCredit, hasPersonalGuarantor } = this.state;
        const { partners, isvUsers, isOpen } = this.props;

        return (
            <Modal
                title="Create New Application"
                isOpen={isOpen}
                handleClose={this.handleCancel}
                className="create-app-modal"
            >
                <form data-testid="create-app-modal-form" className="options" onSubmit={this.handleSubmit}
                    noValidate>
                    <div className="config-options">
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="partnerID" className="left">
                                    * Partner Name
                                </label>
                                <Select
                                    id="partnerID"
                                    name="partnerID"
                                    handleChange={this.handleSelectChange}
                                    value={this.state.partnerID}
                                    options={partners}
                                    required={true}
                                />
                            </div>
                            <div className="cell">
                                <label htmlFor="pricingType" className="left">
                                    * Pricing Type
                                </label>
                                <Select
                                    id="pricingType"
                                    name="pricingType"
                                    handleChange={this.handleSelectChange}
                                    value={this.state.pricingType}
                                    options={pricingTypes}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="isvRep" className="left">
                                    * ISV Rep
                                </label>
                                <Select
                                    id="isvRep"
                                    name="isvRep"
                                    handleChange={this.handleSelectChange}
                                    value={this.state.isvRep}
                                    options={isvUsers}
                                    required={true}
                                />
                            </div>
                            <div className="cell">
                                <label htmlFor="optBlue" className="left">
                                    * OptBlue
                                </label>
                                <Select
                                    id="optBlue"
                                    name="optBlue"
                                    handleChange={this.handleSelectChange}
                                    value={this.state.optBlue}
                                    options={optBlueOptions}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="contact" className="left">
                                    * Contact
                                </label>
                                <input
                                    className="contact-select  text-input"
                                    type="text"
                                    id="contact"
                                    name="contact"
                                    onChange={this.handleContactChange}
                                    value={contact}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="checkCredit" className="left">
                                    Check Credit
                                </label>
                                <input
                                    type="checkbox"
                                    id="checkCredit"
                                    name="checkCredit"
                                    className="checkbox"
                                    onChange={this.handleCheckCreditChange}
                                    checked={isChecked(checkCredit)}
                                />
                            </div>
                        </div>
                        <div className="options-row">
                            <div className="cell">
                                <label htmlFor="hasPersonalGuarantor" className="left">
                                    Personal Guarantor
                                </label>
                                <input
                                    type="checkbox"
                                    id="hasPersonalGuarantor"
                                    name="hasPersonalGuarantor"
                                    className="checkbox"
                                    onChange={this.handleHasPersonalGuarantorChange}
                                    checked={isChecked(hasPersonalGuarantor)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="application-container">
                        <button disabled={isAppCreating} className="create-button-show">
                            {isAppCreating ? 'Loading...' : 'Create Application'}
                        </button>
                    </div>
                </form>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    partners: getAppPartnersNames(state),
    processingBanks: getProcessingBanks(state),
    appPartnersRepCodes: getAppPartnersRepCodes(state),
    isvUsers: getIsvUsers(state)
});

export default connect(mapStateToProps)(CreateAppModal);
